<template>
  <div
    class="mt-5 mx-auto max-w-xs w-fit flex items-center justify-center gap-y-4 relative"
  >
    <ClientOnly>
      <GoogleSignInButton
        @success="handleLoginSuccess"
        @error="handleLoginError"
        :shape="'pill'"
        size="large"
        :locale="localeProperties.iso"
        :text="'continue_with'"
      ></GoogleSignInButton>
    </ClientOnly>
    <div
      v-if="loading"
      class="absolute w-full h-full top-0 left-0 flex items-center justify-center rounded-full cursor-not-allowed bg-slate-50 bg-opacity-50"
    >
      <svg viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" class="w-6">
        <defs>
          <linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a">
            <stop stop-color="#0d9488" stop-opacity="0" offset="0%" />
            <stop stop-color="#0d9488" stop-opacity=".631" offset="63.146%" />
            <stop stop-color="#0d9488" offset="100%" />
          </linearGradient>
        </defs>
        <g fill="none" fill-rule="evenodd">
          <g transform="translate(1 1)">
            <path
              d="M36 18c0-9.94-8.06-18-18-18"
              id="Oval-2"
              stroke="url(#a)"
              stroke-width="2"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 18 18"
                to="360 18 18"
                dur="0.9s"
                repeatCount="indefinite"
              />
            </path>
            <circle fill="#000" cx="36" cy="18" r="1">
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 18 18"
                to="360 18 18"
                dur="0.9s"
                repeatCount="indefinite"
              />
            </circle>
          </g>
        </g>
      </svg>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { storeToRefs } from "pinia";
import {
  GoogleSignInButton,
  type CredentialResponse,
} from "vue3-google-signin";
import { useAuthStore } from "~/stores/auth";
import { useConfigStore } from "~/stores/config";
const { localeProperties } = useI18n();
const { signSocialite } = useAuthStore();
const { auth_modal } = storeToRefs(useConfigStore());
const props = defineProps({
  redirect: {
    type: Boolean,
    default: false,
  },
});
const loading = ref(false);
// handle success event
const handleLoginSuccess = (response: CredentialResponse) => {
  const { credential } = response;
  if (credential) {
    loading.value = true;
    signSocialite({
      access_token: credential,
      service: "google",
    })
      .then((res) => {
        loading.value = false;
        auth_modal.value = false;
        if (props.redirect) {
          navigateTo("/dashboard");
        }
      })
      .catch((err) => {
        loading.value = false;
      });
  }
};

// handle an error event
const handleLoginError = () => {
  console.error("Login failed");
};
</script>
